export const BUDGET_FORMULA_DIALOG_ROUTER = {
    path: 'budget-formula-dialog',
    outlet: 'budget-formula-outlet',
    loadChildren: () =>
        import('../budget-accounts/budget-account-form/formula-dialog/budget-formula-dialog.module').then(
            m => m.BudgetFormulaDialogModule
        ),
};

export const BUDGET_ACCOUNT_IMPORT_DIALOG_ROUTER = {
    path: 'budget-account-import-dialog',
    outlet: 'budget-account-import-outlet',
    loadChildren: () =>
        import('../budget-accounts/account-upload-dialog/account-import-dialog.module').then(
            m => m.AccountImportDialogModule
        ),
};

export const BUDGET_CONTRA_DIALOG_ROUTER = {
    path: 'budget-contra-dialog',
    outlet: 'budget-contra-outlet',
    loadChildren: () =>
        import('../budget-accounts/budget-account-form/contra-dialog/budget-contra-account-dialog.module').then(
            m => m.BudgetContraAccountDialogModule
        ),
};

export const BUDGET_INTEGRATION_CODE_DIALOG_ROUTER = {
    path: 'budget-integration-code-dialog',
    outlet: 'budget-integration-code-outlet',
    loadChildren: () =>
        import('../budget-accounts/budget-account-form/integration-codes-dialog/integration-codes-dialog.module').then(
            m => m.IntegrationCodesDialogModule
        ),
};

export const FORMULA_DIALOG_ROUTER = {
    path: 'formula-dialog',
    outlet: 'formula-outlet',
    loadChildren: () => import('../formula-editor/formula-dialog.module').then(m => m.FormulaDialogModule),
};

export const BUDGET_EXCEL_TEMPLATE_DIALOG_ROUTER = {
    path: 'budget-excel-template-dialog',
    outlet: 'budget-excel-template-outlet',
    loadChildren: () =>
        import('../budget-excel-template/dialog/budget-excel-template-dialog.module').then(
            m => m.BudgetExcelTemplateDialogModule
        ),
};

export const BUDGET_EXCEL_TEMPLATE_SHARE_DIALOG_ROUTER = {
    path: 'budget-excel-template-share-dialog',
    outlet: 'budget-excel-template-share-outlet',
    loadChildren: () =>
        import('../budget-excel-template/share/budget-excel-template-share-dialog.module').then(
            m => m.BudgetExcelTemplateShareDialogModule
        ),
};

export const BUDGET_REQUEST_DIALOG_ROUTER = {
    path: 'budget-request-dialog',
    outlet: 'budget-request-outlet',
    loadChildren: () =>
        import('../budget-expense-request/request-dialog/request-dialog.module').then(m => m.RequestDialogModule),
};

export const BUDGET_REQUEST_DECLINE_DIALOG_ROUTER = {
    path: 'budget-request-decline-dialog',
    outlet: 'budget-request-decline-outlet',
    loadChildren: () =>
        import('../budget-expense-request/decline-dialog/decline-dialog.module').then(
            m => m.RequestDeclineDialogModule
        ),
};

export const BUDGET_REQUEST_INBOX_DIALOG_ROUTER = {
    path: 'budget-request-inbox-dialog',
    outlet: 'budget-request-inbox-outlet',
    loadChildren: () =>
        import('../budget-expense-request/request-inbox/dialog/request-inbox-dialog.module').then(
            m => m.RequestInboxDialogModule
        ),
};

export const TIMETABLE_CLASS_DIALOG_ROUTER = {
    path: 'timetable-class-dialog',
    outlet: 'timetable-class-outlet',
    loadChildren: () =>
        import('../timetable/classes/dialog/class-dialog.module').then(m => m.TimetableClassDialogModule),
};

export const TIMETABLE_ROOM_DIALOG_ROUTER = {
    path: 'timetable-room-dialog',
    outlet: 'timetable-room-outlet',
    loadChildren: () => import('../timetable/rooms/dialog/room-dialog.module').then(m => m.TimetableRoomDialogModule),
};

export const TIMETABLE_TEACHER_DIALOG_ROUTER = {
    path: 'timetable-teacher-dialog',
    outlet: 'timetable-teacher-outlet',
    loadChildren: () =>
        import('../timetable/teachers/dialog/teacher-dialog.module').then(m => m.TimetableTeacherDialogModule),
};

export const TIMETABLE_COURSE_DIALOG_ROUTER = {
    path: 'timetable-course-dialog',
    outlet: 'timetable-course-outlet',
    loadChildren: () =>
        import('../timetable/courses/dialog/course-dialog.module').then(m => m.TimetableCourseDialogModule),
};
export const TIMETABLE_PROJECT_DIALOG_ROUTER = {
    path: 'timetable-project-dialog',
    outlet: 'timetable-project-outlet',
    loadChildren: () =>
        import('../timetable/projects/dialog/project-dialog.module').then(m => m.TimetableProjectDialogModule),
};
export const TIMETABLE_PROJECT_COPY_DIALOG_ROUTER = {
    path: 'timetable-project-copy-dialog',
    outlet: 'timetable-project-copy-outlet',
    loadChildren: () =>
        import('../timetable/projects/copy/project-copy-dialog.module').then(m => m.ProjectCopyDialogModule),
};
export const TIMETABLE_TRIAL_DIALOG_ROUTER = {
    path: 'timetable-trial-dialog',
    outlet: 'timetable-trial-outlet',
    loadChildren: () =>
        import('../timetable/trials/dialog/trial-dialog.module').then(m => m.TimetableTrialDialogModule),
};

export const TIMETABLE_TRIAL_COPY_DIALOG_ROUTER = {
    path: 'timetable-trial-copy-dialog',
    outlet: 'timetable-trial-copy-outlet',
    loadChildren: () =>
        import('../timetable/trials/copy/trial-copy-dialog.module').then(m => m.TimetableTrialCopyDialogModule),
};

export const TEACHER_OUTLET = 'teacher-router';
export const STUDENT_OUTLET = 'student-router';
export const PRIMARY_OUTLET = 'primary';
